import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import useFetch from "use-http";
import AppProvider from "../../components/AppProvider";
import { PaymentDataDaum, PaymentDataRoot } from "../../utils/types";

const PaymentReport = () => {
  const [key, setKey] = useState({
    android: Math.random(),
    ios: Math.random(),
  });
  return (
    <>
      <AppProvider>
        <Container fluid className="mt-3">
          <Tabs
            defaultActiveKey="android"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(e) => {
              e === "android"
                ? setKey({ ...key, android: Math.random() })
                : setKey({ ...key, ios: Math.random() });
            }}
          >
            <Tab eventKey="android" title="Android">
              <AndroidPaymentReport key={key.android} />
            </Tab>
            <Tab eventKey="ios" title="IOS">
              <IosPaymentReport key={key.ios} />
            </Tab>
          </Tabs>
        </Container>
      </AppProvider>
    </>
  );
};

const AndroidPaymentReport = () => {
  const [data, setData] = useState<PaymentDataDaum[]>([]);
  const { get, loading, response } = useFetch<PaymentDataRoot>("/payment");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      const data = await get("/android");
      if (data.success) setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row>
            <h6>Android Payment Report</h6>

            <Formik
              initialValues={{
                fromDate: "",
                toDate: "",
              }}
              onSubmit={() => console.log("")}
            >
              {() => <></>}
            </Formik>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Tracking Id</th>
                <th>Payment Status</th>
                <th>Amount</th>
                <th>Transaction Date</th>
                <th>Parent</th>
                <th>Student</th>
                <th>Standard</th>
                <th>Branch</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              {data.map((x) => (
                <tr>
                  <td>{x.orderId}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{x.amount}</td>
                  <td>{}</td>
                  <td>{x.parent.name}</td>
                  <td>{x.student.name}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{moment(x.year).format("YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export interface Root {
  success: boolean;
  message: string;
  data: Data;
}

export interface Data {}

const IosPaymentReport = () => {
  const [data, setData] = useState<PaymentDataDaum[]>([]);
  const { get, loading, response } = useFetch<PaymentDataRoot>("/payment");
  const { post } = useFetch<Root>("/ios");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      const data = await get("/ios");
      if (data.success) setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const checkStatus = async (encResp: string) => {
    try {
      const res = await post("/status", {
        encResp: encResp,
      });

      await fetchData();
    } catch (err) {}
  };

  if (loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row>
            <h6>IOS Payment Report</h6>

            <Formik
              initialValues={{
                fromDate: "",
                toDate: "",
              }}
              onSubmit={() => console.log("")}
            >
              {() => <></>}
            </Formik>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Tracking Id</th>
                <th>Payment Status</th>
                <th>Amount</th>
                <th>Transaction Date</th>
                <th>Parent</th>
                <th>Student</th>
                <th>Standard</th>
                <th>Branch</th>
                <th>Year</th>
                {/* <th>Refresh</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((x) => (
                <tr>
                  <td>{x.orderId}</td>
                  <td>{x.trackingId}</td>
                  <td>{x.status}</td>
                  <td>{x.amount}</td>
                  <td>{x.transactionDate}</td>
                  <td>{x.parent.name}</td>
                  <td>{x.student.name}</td>
                  <td>{x.student.standard.name}</td>
                  <td>{x.student.branch.name}</td>
                  <td>{moment(x.year).format("YYYY")}</td>
                  {/* <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => checkStatus(x.enc_val)}
                    >
                      Status
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentReport;
