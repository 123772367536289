const PrivacyPolicy = () => {
  return (
    <div
      style={{
        margin: 10,
        padding: 10,
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> 01-01-2025 (DD-MM-YYYY)
      </p>

      <p>
        <strong>SCPL CONNECT</strong> ("we," "our," or "us") is committed to
        protecting your privacy. This Privacy Policy explains how we collect,
        use, and protect information when you use our mobile application ("the
        App").
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        <strong>No Personal Data Collection:</strong> SCPL CONNECT does not
        collect personal data from users.
      </p>
      <p>
        <strong>Login Information:</strong> To use the app, users (students and
        parents) must log in with a username and password provided by the
        educational institute. These credentials are used solely for
        authentication purposes and are securely managed by the educational
        institution.
      </p>
      <p>
        <strong>Calendar Permissions:</strong> The app requires calendar
        permissions to sync lectures and tests with Google Calendar. This
        functionality does not store or collect calendar data on our servers.
      </p>

      <h2>2. How We Use Information</h2>
      <p>
        <strong>Calendar Sync:</strong> The calendar permission is used
        exclusively to sync events such as lectures and tests to your Google
        Calendar.
      </p>
      <p>
        <strong>Student and Parent Access:</strong> Parents can log in to the
        app to view student records, such as lectures, tests, reports, study
        materials, make appointments with teachers, and make fee payments.
      </p>

      <h2>3. Third-Party Services</h2>
      <p>
        <strong>Payment Gateway:</strong> The app integrates with a third-party
        payment gateway for fee payments. By using this feature, you agree to
        comply with the terms of the payment gateway.
      </p>
      <p>
        The payment gateway may collect personal and payment information
        necessary to process transactions. Please refer to their privacy policy
        for more details:{" "}
        <a href="[Insert Payment Gateway Privacy Policy Link]" target="_blank">
          Payment Gateway Privacy Policy
        </a>
        .
      </p>

      <h2>4. User Rights</h2>
      <p>
        Users (parents) can revoke calendar permissions at any time through
        their device settings.
      </p>
      <p>
        Parents can also request account-related actions, including account
        deletion, by contacting us directly via email.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement industry-standard security measures to protect your data,
        including encryption and secure storage on our servers.
      </p>
      <p>
        SCPL CONNECT does not collect or share any user data with third parties,
        except as required for processing payments through the third-party
        payment gateway.
      </p>

      <h2>6. Sharing of Data</h2>
      <p>
        We do not share any user data with third parties, except for the
        necessary information required for payment processing through the
        payment gateway.
      </p>
      <p>The app does not collect or store any calendar data on our servers.</p>

      <h2>7. Age Restrictions</h2>
      <p>
        SCPL CONNECT is designed for students aged 16 years and above, such as
        those in 11th and 12th grades and college students, as well as their
        parents who wish to view students’ records.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy or need assistance,
        you can contact us at:
      </p>
      <p>
        <strong>Email:</strong> [Insert Your Email Address]
      </p>
    </div>
  );
};

export default PrivacyPolicy;
